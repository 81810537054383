/* General App Styles */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: #ffc800;
  color: #ff7300;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  background-color: #ffc800;


  padding-bottom: 6%;
  /* Adjusts for the height of the footer */


  /* Prevents horizontal scroll */
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.first-title {
  color: rgb(0, 0, 0);
  margin-bottom: 50vh;
  font-size: 3rem;
}

.Login,
.Login:hover {
  background-color: #fffb00;
  color: rgb(0, 0, 0);
  font-size: 2rem;
  border-style: solid;
  cursor: pointer;
  border-radius: 25px;
  border-color: black;
  border-width: 2px;
}

.Login:hover {
  background-color: #0e0804;
  color: rgb(255, 196, 0);
  font-size: 2rem;
  font-style: bold;
  transition: background-color 0.3s;
  border-color: antiquewhite;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  /* Align title and button to opposite ends */
  align-items: center;
  /* Center vertically */
  margin-bottom: 20px;
  /* Space below header */
}

.header h1 {
  font-size: 2em;
  /* Increase font size for the title */
  margin: 0;
  /* Remove default margin */
}

/* Logout Button Styles */
.logout-button {
  background-color: #f44336;
  /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #d32f2f;
  /* Darker red on hover */
}





.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  /* Background color for visibility */
  color: #fff;
  /* Ensure text is white and visible */
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content {
  text-align: center;
}

.popup-content a {
  color: #4caf50;
  /* Link color */
  text-decoration: none;
}

.popup-content a:hover {
  text-decoration: underline;
}



/* Responsive Styles */
@media (max-width: 768px) {
  .header h1 {
    font-size: 1.5em;
    /* Reduce font size for smaller screens */
  }

  .logout-button,
  .load-more,
  .schema-button {
    padding: 8px 16px;
    /* Reduce padding for buttons */
    font-size: 14px;
    /* Reduce font size */
  }

  .data-card h2 {
    font-size: 1.2em;
    /* Smaller heading */
  }

  .login-and-gitbook {
    grid-template-columns: 1fr;
    /* 1 column for smaller screens */
  }
}