.foot {
    position: fixed;
    /* Fix the footer to the viewport */
    bottom: 0;
    /* Position it at the bottom */
    left: 0;
    /* Align to the left edge */
    width: 100%;
    /* Full width */
    height: 6%;
    /* Fixed height */
    background-color: #13131d;
    /* Background color */
    color: #fff;
    /* Text color */
    display: flex;
    /* Flexbox layout */
    justify-content: space-between;
    /* Space between items */
    align-items: center;
    /* Center items vertically */
    padding: 5px 10px;
    /* Reduced padding for responsiveness */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* Shadow */
    z-index: 1000;
    /* Ensure it's on top */
    /* Round top corners */
    font-size: 0.8rem;
    /* Base font size */
}

.user-info-bals {
    display: grid;
    grid-template-rows: repeat(2, auto);
    max-height: 6%;
    /* Keep the max height */
}

.user-bals {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-left: 5px;
    /* Adjust margin if necessary */
}

.foot-left,
.foot-center,
.foot-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* Space evenly */
    flex-wrap: nowrap;
    /* Prevent wrapping for children */
}

.foot-right {
    margin-right: 10px;
    /* Reduced margin for responsiveness */
}

.foot-right h4,
.foot-right h5 {
    font-size: 0.6rem;
    /* Smaller font size */
    margin: 0;
    /* Remove margin for tighter spacing */
}

.foot-link.selected {
    color: rgb(255, 0, 170);
    /* Highlight selected link */
}

.foot-logo {
    font-size: 0.8rem;
    /* Smaller logo font size */
    color: #fff;
    /* Logo color */
    text-decoration: none;
    /* Remove underline */
    display: flex;
    /* Use flex for h5 */
    align-items: center;
    /* Center items vertically */
}

.foot-link {
    margin: 0 5px;
    /* Reduced margin for links */
    color: #fff;
    /* Link color */
    text-decoration: none;
    /* Remove underline */
    font-size: 0.8rem;
    /* Smaller font size */
}

.foot-link:hover {
    text-decoration: underline;
    /* Underline on hover */
}

.logout-button {
    background-color: #bb5952;
    /* Background color */
    color: white;
    /* Text color */
    border: none;
    /* No border */
    padding: 5px 10px;
    /* Reduced padding */
    cursor: pointer;
    /* Pointer cursor */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Transition effect */
    font-size: 0.8rem;
    /* Smaller font size */
    margin-left: 5px;
    /* Reduced margin */
    margin-right: 10px;
    /* Reduced margin */
}

.logout-button:hover {
    background-color: #d32f2f;
    /* Change background color on hover */
}

/* Media Queries for further responsiveness */
@media (max-width: 768px) {
    .foot {
        height: 8%;
        /* Increase height on smaller screens */
        font-size: 0.7rem;
        /* Smaller base font size */
    }

    .foot-right h4,
    .foot-right h5,
    .foot-logo,
    .foot-link {
        font-size: 0.7rem;
        /* Smaller font size for links and logos */
    }

    .logout-button {
        font-size: 0.7rem;
        /* Smaller logout button font size */
    }
}

@media (max-width: 480px) {
    .foot {
        height: 10%;
        /* Increase height even more for small screens */
        font-size: 0.5rem;
        /* Smaller base font size */
    }

    .foot-right h4,
    .foot-right h5,
    .foot-logo,
    .foot-link {
        font-size: 0.5rem;
        /* Smaller font size for links and logos */
    }

    .logout-button {
        font-size: 0.6rem;
        /* Smaller logout button font size */
    }
}