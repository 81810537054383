.token-page,
.tokens {
    width: 100%;

    justify-content: center;
    display: flex;
}

.stakes {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.headers {
    color: black;
    background-color: #ffc800;
    justify-content: space-around;
    display: flex;
    border-bottom: 1px solid black;
    border-radius: 25px 1px 25px 25px;
}

.token-info {
    width: 40%;

    border-radius: 25px 1px;
    border: 1px solid black;
    height: 100%;
    background-color: #ff8801e8;
    padding-bottom: 5vh;
}

.form-container {
    padding-top: 5%;
    display: flex;
    justify-content: space-around;
    color: black;
    text-align: center;
}

p {
    padding-top: 2%;
}