/* Asset Card Styles */
.asset-card,
.asset-card-small {
    background-color: #000000;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    /* Allow height to adjust */
    min-height: 250px;
    /* Ensure minimum height for consistency */
    width: 15vw;
}

.asset-card-small {
    height: auto;
    /* Allow height to adjust */
    min-height: 80px;
    /* Ensure minimum height for small cards */
    width: 80px;
    /* Fixed width for small cards */
}

/* NFT Image Styles */
.nft-video,
.nft-image {
    width: 100%;
    /* Full width of the card */
    height: auto;
    /* Auto height */
    aspect-ratio: 1;
    /* Keep images square */
    object-fit: cover;
    /* Maintain aspect ratio */
    margin: 5px 0;
}

.nft-video-small,
.nft-image-small {
    width: 100%;
    /* Use full width for small images */
    height: auto;
    /* Allow height to adjust */
    aspect-ratio: 1;
    /* Keep small images square */
    object-fit: cover;
    /* Maintain aspect ratio */
}

/* Text Styles */
.asset-card p,
.asset-card .mint-number,
.asset-card .asset-name {
    margin: 5px 0;
    /* Adjust margin for better spacing */
}

.asset-card .mint-number {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: auto;
    color: #ffffff;
}

.asset-card .asset-name,
.asset-card-small .asset-name {
    margin-top: auto;
    font-weight: bold;
    color: #ffffff;
}

.asset-card-small .asset-name {
    font-size: 0.55rem;
}

.asset-card-small .mint-number {
    font-size: 0.8rem;
    margin-top: auto;
}

.asset-card.selected,
.asset-card-small.selected,
.asset-card-small.selected .mint-number,
.asset-card-small.selected .asset-name,
.asset-card.selected .mint-number,
.asset-card.selected .asset-name {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {

    .asset-card,
    .asset-card-small {
        width: 100%;
        /* Full width on mobile */
        height: auto;
        /* Height adjusts automatically */
        padding: 0.5rem;
        /* Reduce padding for smaller screens */
    }

    .asset-card {
        min-height: 10vh;
        /* Adjust minimum height for smaller screens */
    }

    .asset-card-small {
        min-height: 8vh;
        /* Adjust minimum height for smaller small cards */
    }

    .asset-card .mint-number,
    .asset-card .asset-name,
    .asset-card-small .mint-number,
    .asset-card-small .asset-name {
        font-size: 0.9rem;
        /* Smaller font size for mobile */
    }
}

@media (max-width: 400px) {

    .asset-card,
    .asset-card-small {
        padding: 0.25rem;
        /* Further reduce padding on very small screens */
    }

    .asset-card .mint-number,
    .asset-card .asset-name,
    .asset-card-small .mint-number,
    .asset-card-small .asset-name {
        font-size: 0.8rem;
        /* Further reduce font size for very small screens */
    }
}