/* Global Box Sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Landing Page Styles */
.Landing {
    align-content: center;
    justify-content: center;
    width: 100%;
}

.schema-filter {
    margin: 20vh;
    align-items: start;
}

.Landing-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    width: 100%;
    height: auto;

    margin-top: 5vh;
    align-content: center;
    justify-content: space-around;
    overflow-y: auto;
    overflow-x: hidden;
}

.Landing-Div-Left,
.Landing-Div-Right {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
}

/* Updated assets grid */
.assets-grid-dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Responsive grid */
    justify-content: space-evenly;
    padding: 10px;
    width: 100%;
    margin: 5vh 6vw 0;
    /* Margin on top and sides */
    gap: 5px;
    /* Consistent gap */
    overflow-y: auto;
    /* Allow flexibility */
    margin: 0 auto;
}

/* Load More Button */
.load-more {
    margin: 20px auto;
    margin-bottom: 15vh;
    display: block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.return-button,
.deposit-button {
    margin: 20vh;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;

}


.load-more:hover,
.return-button:hover,
.deposit-button:hover {
    background-color: #0056b3;
}

.dynamic-content {
    color: black;
    font-size: 1rem;
}

.return-deposit-stake-button {
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.transfer-buttons {
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0vh;
}

.transfer-button {
    height: 10vh;
    border-radius: 15px;
    width: 20%;
    align-items: center;
    margin-top: 0vh;

    background-color: black;
    color: #ffc800;
    text-size-adjust: 1rem;

}

.transfer-button:hover {
    flex-direction: row;
    /* Stack buttons vertically */
    align-items: center;
    background-color: rgb(167, 95, 1);
    color: #ffd94f;
    border-color: white;
    /* Center the buttons */
}

.niche-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px 20px;
    width: 100%;
    margin: 1%;

}

.niche-buttons2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .Landing {
        padding: 0 0.5rem;
        /* Less padding on smaller screens */
    }

    .schema-filter {
        margin: 5vw 0;
    }

    .transfer-buttons {
        display: flex;
        flex-direction: row;
        /* Stack buttons vertically */
        align-items: center;
        /* Center the buttons */
        justify-content: space-between;
        width: 100%;
        margin-top: 0vh;
    }



    .transfer-button {
        height: 10vh;
        width: 30%;
        margin: 0.5rem;
    }

    .transfer-button:hover {
        flex-direction: row;
        /* Stack buttons vertically */
        align-items: center;
        background-color: rgb(167, 95, 1);
        color: #ffd94f;
        border-color: white;
        /* Center the buttons */
    }

    .return-deposit-stake-button {
        flex-direction: row;
        width: 100%;
        /* Stack buttons vertically */
        align-items: center;
        margin-top: 10vh;
        /* Center the buttons */
    }

    .dynamic-content {
        text-align: center;
        /* Center text on smaller screens */
    }

    .assets-grid-dashboard {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        /* Responsive grid */
        justify-content: space-around;
        padding: 10px;
        width: 100%;
        margin: 5vh 6vw 0;
        /* Margin on top and sides */
        gap: 5px;
        /* Consistent gap */
        overflow-y: auto;
        /* Allow flexibility */
        margin: 0 auto;
    }

    .return-button,
    .deposit-button {
        margin: 10vw;
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;

    }

    .Landing-Container {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        width: 100%;
        height: auto;
        margin-top: 5vh;
        align-content: center;
        justify-content: space-around;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .load-more {
        margin-left: 0 auto;
        display: block;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
    }
}

@media (max-width: 480px) {
    .schema-filter {
        margin-left: 10vw;
    }

    .transfer-button {
        flex-direction: row;
        /* Stack buttons vertically */
        align-items: center;

        font-size: 14px;
        /* Smaller font size for buttons */
        margin: 0.25rem;
    }

    .return-button,
    .deposit-button,
    .load-more {
        font-size: 14px;
        /* Smaller font size */
        padding: 8px 16px;
        /* Smaller padding */
    }

    .transfer-buttons {
        display: flex;
        flex-direction: column;
        grid-template-columns: 40% 40%;
        /* Stack buttons vertically */
        align-items: center;
        /* Center the buttons */
        justify-content: space-between;
        width: 100%;
        margin-top: 0vh;
    }



    .transfer-button {
        height: 10vh;
        width: 40%;
    }

    .transfer-button:hover {
        /* Stack buttons vertically */
        align-items: center;

        background-color: rgb(167, 95, 1);
        color: #ffd94f;
        border-color: white;
        /* Center the buttons */
    }
}